<template>
  <div>

    <el-row>
      <el-button type="primary" @click="handleCreate">添加</el-button>
    </el-row>
    <!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">

        <el-form-item label="职位" prop="position">
          <el-input v-model="temp.position" />
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="temp.name" />
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="temp.phone" />
        </el-form-item>
        <el-form-item label="微信" prop="wechat">
          <el-input v-model="temp.wechat" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="temp.email" />
        </el-form-item>
        <el-form-item label="qq" prop="qq">
          <el-input v-model="temp.qq" />
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <!--表单 END -->
    <br />
    <el-table ref="singleTable" :data="tableData" border fit style="width: 100%;height: 100%">

      <el-table-column fixed fit align="center" type="index" min-width="10" />


      <el-table-column prop="position" label="职位" min-width="50" />

      <el-table-column prop="name" label="姓名" min-width="40" />

      <el-table-column prop="phone" label="电话" min-width="40" />

      <el-table-column prop="wechat" label="微信" min-width="40" />

      <el-table-column prop="email" label="邮箱" min-width="40" />

      <el-table-column prop="qq" label="qq" min-width="40" />

      <el-table-column label="操作" align="center" min-width="70" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { listApi,addApi,updateApi,delApi } from '@/api/customerContant'
import Pagination from '@/components/Pagination'

export default {
  name: "customerContant",
  props: ['row'],
  components: {Pagination },
  data() {
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 20,
        customerId: '',
      },
      dialogFormVisible: false,
      dialogFormTotalConfig:false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        position: [{ required: true, message: '不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '不能为空', trigger: 'blur' }],
        phone: [{ required: true, message: '不能为空', trigger: 'blur' }],
        wechat: [{ required: true, message: '不能为空', trigger: 'blur' }],
        email: [{ required: true, message: '不能为空', trigger: 'blur' }],

      },
      temp: {
        id: undefined,
        customerId: '',
        position: '',
        name: '',
        phone: '',
        wechat: '',
        email: '',
        qq: '',
      },
      tableData: [],

    }
  },
  created() {
    this.getList();

  },
  watch:{
    row: function (n ,o) {
      console.info(112233)
      console.info(n.id)
      console.info(o.id)

      this.getList();
    }
  },
  methods:{

    getList() {
      this.listQuery.customerId = this.row.id;
      console.info(this.listQuery)
      listApi(this.listQuery).then(response => {
        this.tableData = response.body.records
        this.total = response.body.total
      })
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.customerId = this.row.id
          addApi(this.temp).then(() => {
            //this.tableData.unshift(this.temp)
            this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        customerId: '',
        position: '',
        name: '',
        phone: '',
        wechat: '',
        email: '',
        qq: '',

      }
    },
    handleUpdate(index, row) {
      this.temp = Object.assign({}, row) // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp)
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          tempData.customerId = this.row.id;
          updateApi(tempData).then(() => {
            const index = this.tableData.findIndex(v => v.id === this.temp.id)
            this.tableData.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApi(row).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.tableData.splice(index, 1)
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },

    handleTotalSet(index, row){
      console.log(index, row);
      this.dialogFormTotalConfig = true
      this.pdTotalsetTemp = row;
    },
    handleDetailedSet(index, row){
      console.log(index, row);

    },
    totalConfigVisible(v){
      this.dialogFormTotalConfig = v
    }


  }
}

</script>

<style scoped>


</style>
